<template>
  <div class="section">
    <loading
      :active.sync="isLoading"
      loader="spinner"
      color="#4599C0"
      :is-full-page="true"
    />
    <div class="container pt-2">
      <div class="row text-center">
        <div class="col-md-12 mb-3">
          <h2 class="title mb-0">eLog User Registration Form</h2>
          <h4 class="mt-0">Let's get started</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 ml-auto mr-auto col-md-8">
          <ValidationObserver ref="form">
            <div class="row mb-3">
              <div class="col-md-6">
                <ValidationProvider
                  name="First Name"
                  :rules="{
                    required: true,
                    regex: /^[ÑA-Za-z][ñA-Za-z- ]{1,30}$/,
                  }"
                  v-slot="{ errors }"
                >
                  <label for="first_name">First Name</label>
                  <span class="form-text text-danger label-hint">{{
                    errors[0]
                  }}</span>
                  <input
                    type="text"
                    class="form-control"
                    id="first_name"
                    v-model="form.firstName"
                    maxlength="30"
                  />
                </ValidationProvider>
              </div>
              <div class="col-md-6">
                <ValidationProvider
                  name="Last Name"
                  :rules="{
                    required: true,
                    regex: /^[ÑA-Za-z][ñA-Za-z- ]{1,30}$/,
                  }"
                  v-slot="{ errors }"
                >
                  <label for="last_name">Last Name</label>
                  <span class="form-text text-danger label-hint">{{
                    errors[0]
                  }}</span>
                  <input
                    type="text"
                    class="form-control"
                    id="last_name"
                    v-model="form.lastName"
                    maxlength="30"
                  />
                </ValidationProvider>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-md-12">
                <ValidationProvider
                  name="Address"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="address">Address</label>
                  <span class="form-text text-danger label-hint">{{
                    errors[0]
                  }}</span>
                  <input
                    type="text"
                    class="form-control"
                    id="address"
                    v-model="form.address"
                  />
                </ValidationProvider>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-md-6">
                <ValidationProvider
                  name="Contact No"
                  :rules="{ required: true, max: 11, regex: /^09[0-9]{9}$/ }"
                  v-slot="{ errors }"
                >
                  <label for="phone_number">Contact No</label>
                  <span class="form-text text-danger label-hint">{{
                    errors[0]
                  }}</span>
                  <input
                    type="text"
                    class="form-control"
                    id="phone_number"
                    v-model="form.contactNo"
                  />
                  <span class="form-text label-hint-small mt-1"
                    >11-digit number, i.e. 0905xxxxxx</span
                  >
                </ValidationProvider>
              </div>
              <div class="col-md-6">
                <ValidationProvider
                  vid="email"
                  name="Email"
                  rules="email"
                  v-slot="{ errors }"
                >
                  <label for="email_address">Email Address</label>
                  <span class="form-text text-danger label-hint">{{
                    errors[0]
                  }}</span>
                  <input
                    type="email"
                    class="form-control"
                    id="email_address"
                    v-model="form.email"
                    maxlength="50"
                  />
                  <span class="form-text label-hint-small mt-1"
                    >This will become your account's username</span
                  >
                </ValidationProvider>
              </div>
            </div>

            <div
              class="row mt-2 mb-3"
              v-show="form.email && form.email.length > 0"
            >
              <div class="col-md-6">
                <ValidationProvider
                  vid="password"
                  name="Password"
                  :rules="{ required: false, max: 6, regex: /^([0-9]{6})$/ }"
                  v-slot="{ errors }"
                >
                  <label for="password">Password</label>
                  <span class="form-text text-danger label-hint">{{
                    errors[0]
                  }}</span>
                  <input
                    type="password"
                    class="form-control"
                    id="password"
                    v-model="form.password"
                  />
                  <span class="form-text label-hint-small mt-1"
                    >Password must be a 6-digit no.</span
                  >
                </ValidationProvider>
              </div>
              <div class="col-md-6">
                <ValidationProvider
                  name="Confirm Password"
                  :rules="{
                    required: false,
                    max: 6,
                    confirmed: 'password',
                    regex: /^([0-9]{6})$/,
                  }"
                  v-slot="{ errors }"
                >
                  <label for="confirm_password">Confirm Password</label>
                  <span class="form-text text-danger label-hint">{{
                    errors[0]
                  }}</span>
                  <input
                    type="password"
                    class="form-control"
                    id="confirm_password"
                    v-model="confirmPassword"
                  />
                  <span class="form-text label-hint-small mt-1"
                    >Password must be a 6-digit no.</span
                  >
                </ValidationProvider>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="send-button">
                  <button @click="onSubmit" class="btn btn-block submit-button">
                    Register
                  </button>
                </div>
              </div>
            </div>
            <alert-dialog />
          </ValidationObserver>
        </div>
      </div>
    </div>

    <DownloadQRCode
      :newlyCreated="true"
      :isOpen="isDownloadDialogOpen"
      :userDetails="userDetails"
      @on-close="isDownloadDialogOpen = false"
    />
  </div>
</template>

<script>
import { Button, FormGroupInput } from "@/components";
import AlertDialog from "./components/AlertDialog";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import {
  required,
  email,
  confirmed,
  max,
  min,
  numeric,
  regex,
} from "vee-validate/dist/rules";
import api from "../api/user";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import DownloadQRCode from "./components/qrcode/DownloadQRCode";

extend("email", { ...email, message: "{_field_} must be a valid email" });
extend("regex", { ...regex, message: "Invalid {_field_}" });
extend("numeric", { ...numeric, message: "{_field_} must be numeric" });
extend("required", { ...required, message: "{_field_} is required" });
extend("confirmed", { ...confirmed, message: "Your password didn't match" });
extend("max", {
  ...max,
  params: ["length"],
  message: "{_field_} must be a {length}-digit number",
});
extend("min", {
  ...min,
  params: ["length"],
  message: "{_field_} must be a {length}-digit number",
});

export default {
  name: "register",
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    AlertDialog,
    ValidationProvider,
    ValidationObserver,
    Loading,
    DownloadQRCode,
  },
  data() {
    return {
      isLoading: false,
      form: {
        firstName: "",
        lastName: "",
        address: "",
        contactNo: "",
        email: "",
        password: "",
        isActive: true,
        createdBy: "",
        dateCreated: "",
      },
      confirmPassword: "",
      isDownloadDialogOpen: false,
      userDetails: {},
    };
  },
  methods: {
    async onSubmit() {
      const isSuccess = await this.$refs.form.validate();
      if (!isSuccess) {
        return;
      }

      if (this.form.email && !this.form.password) {
        this.$store.commit("alert/SHOW_DIALOG", {
          message: "Please confirm your password",
          status: "error",
        });
        return;
      }

      try {
        if (this.form.email && this.form.email.length > 0) {
          this.form.createdBy = this.form.email;
        }

        this.form.dateCreated = new Date().getTime();
        this.isLoading = true;

        const result = await api.addUser(this.form);

        if (result.data.isValid) {
          this.userDetails = result.data.user;
          this.isDownloadDialogOpen = true;

          // hide loading indicator
          this.isLoading = false;
          this.resetForm();
          this.$refs.form.reset();
        } else {
          // hide loading indicator
          this.isLoading = false;
          this.$store.commit("alert/SHOW_DIALOG", {
            message: result.data.message,
            status: "error",
          });
        }
      } catch (error) {
        // hide loading indicator
        this.isLoading = false;
        this.$store.commit("alert/SHOW_DIALOG", {
          message: error.message,
          status: "error",
        });
      }
    },
    resetForm() {
      this.form = {
        firstName: "",
        lastName: "",
        address: "",
        contactNo: "",
        email: "",
        password: "",
        isActive: true,
        createdBy: "",
        dateCreated: "",
      };
      this.confirmPassword = "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/bootstrap.scss";
.label-hint-small {
  font-size: 10px;
  margin-top: -5px;
}
.label-hint {
  margin-top: -10px;
  margin-bottom: 5px;
  font-size: 10px;
}
.submit-button {
  background-color: #4599c0;
  color: white;
}
.submit-button:hover {
  background-color: #3682a7;
  color: white;
}
</style>