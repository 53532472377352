<template>
  <div>
    <loading
      :active.sync="isLoading"
      loader="spinner"
      color="#4599C0"
      :is-full-page="false"
    />
    <div class="banner-bg clear-filter">
      <div class="container">
        <div class="row text-center">
          <div class="col-md-12">
            <h2>
              <strong>How can we help you?</strong>
            </h2>
            <div class="row justify-content-md-center">
              <div class="col-md-5">
                <fg-input
                  class="no-border form-control-lg search"
                  placeholder="Type keywords to find answer"
                  @input="onSearch"
                  v-model="searchQuery"
                  color="white"
                />
              </div>
            </div>
            <div class="row justify-content-md-center">
              <div class="col-md-4">
                <span v-if="searchQuery !== ''">
                  {{
                    isSearching === true
                      ? "Searching for your question..."
                      : "Did you find the answer?"
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="main">
      <div class="section my-0">
        <div class="container">
          <div class="row">
            <div class="col-md-3">
              <nav class="nav flex-column">
                <a
                  v-for="(category, index) in categories"
                  :key="index"
                  :class="`nav-link ${
                    category === currTab
                      ? 'footer-nav-links-active'
                      : 'footer-nav-links'
                  }`"
                  :href="true"
                  @click.prevent="onTabClick(category)"
                  >{{ category | categoryFormatter }}</a
                >
              </nav>
            </div>
            <div class="col-md-9">
              <template
                v-if="
                  noResultsFound &&
                  !isFormRequestShown &&
                  !isSearching &&
                  searchQuery
                "
              >
                <h4 class="mt-0"><span v-html="noResultsFound"></span></h4>
                <a
                  class="submit-request-button"
                  :href="isFormRequestShown"
                  @click.prevent="isFormRequestShown = true"
                  >Submit a request</a
                >
              </template>
              <FormRequest
                @onSuccess="onRequestSuccess"
                @onCancel="isFormRequestShown = false"
                v-if="isFormRequestShown && searchedItems.length === 0"
              />
              <h4 v-if="!searchQuery" class="text-primary mt-0">
                {{ currTab | categoryFormatter }}
              </h4>
              <collapse>
                <template v-for="(item, index) in searchedItems">
                  <collapse-item
                    class="mb-1"
                    :title="item.question"
                    :titleBadge="searchQuery ? item.category : ''"
                    :name="index + 1"
                    :key="index"
                    :noIcon="true"
                  >
                    <span v-html="item.answer"></span>
                  </collapse-item>
                  <hr :key="index + 'a'" />
                </template>
              </collapse>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Components -->
    <alert-dialog />
  </div>
</template>

<script>
import {
  Tabs,
  TabPane,
  Collapse,
  CollapseItem,
  FormGroupInput as FgInput,
} from "../components";
import api from "../api/faqs";
import _ from "lodash";
import FormRequest from "./components/faqs/FormRequest";
import AlertDialog from "./components/AlertDialog";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "faqs",
  components: {
    Tabs,
    TabPane,
    Collapse,
    CollapseItem,
    FgInput,
    FormRequest,
    AlertDialog,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      searchQuery: "",
      isSearching: false,
      items: [],
      searchedItems: [],
      categories: [], // get this on list items
      currTab: "",
      noResultsFound: "",
      isFormRequestShown: false,
    };
  },
  watch: {
    // Delay Searching
    searchQuery: _.debounce(function (value) {
      this.searchedItems = this.items.filter((item) =>
        item.question.toLowerCase().includes(value.toLowerCase())
      );
      this.isSearching = false;
      this.isFormRequestShown = false;
      this.noResultsFound = "";

      if (!value) {
        this.onTabClick("general");
      }

      if (this.searchedItems.length === 0) {
        this.noResultsFound = `No results for <strong>"${this.searchQuery}"</strong>`;
      }
    }, 1000),
  },
  mounted() {
    this.getFaqs();
  },
  methods: {
    initTabs() {
      this.categories = ["general", "getting_started", "privacy", "contact_us"];
      this.onTabClick("general");
    },
    onTabClick(tab) {
      this.searchQuery = "";
      this.searchedItems = [];
      this.currTab = tab;
      this.searchedItems = this.items.filter((item) => item.category === tab);
    },
    async getFaqs() {
      try {
        this.isLoading = true;
        const { data } = await api.getFaqs(false);
        this.items = _.orderBy(
          Object.values(data.faqs).filter(
            (item) => item.category !== "pending"
          ),
          ["order"],
          ["asc"]
        );
        this.initTabs();

        // hide loading indicator
        this.isLoading = false;
      } catch (error) {
        // hide loading indicator
        this.isLoading = false;
        this.$store.commit("alert/SHOW_DIALOG", {
          message: error.message,
          status: "error",
        });
      }
    },
    onSearch(value) {
      if (value) {
        this.isSearching = true;
        this.searchedItems = this.items;
      } else {
        // Reset selection
        this.onTabClick("general");
      }
    },
    onRequestSuccess() {
      this.onTabClick("general");
      this.$store.commit("alert/SHOW_DIALOG", {
        message: "Your question has been sent!",
        status: "success",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.banner-bg {
  min-height: 50vh;
  max-height: 999px;
  color: #ffffff;
  position: relative;
  overflow: hidden;
  background-image: url("../../public/images/backgrounds/optimized/background-graphic-gradient.svg");
  padding-top: 150px;
}
.test {
  color: white;
}
.footer-nav-links {
  font-weight: 500;
  color: #2c2c2c !important;
  padding-top: 6px;
  padding-bottom: 6px;
  cursor: pointer;
}
.footer-nav-links-active {
  font-weight: 500;
  color: #4599c0 !important;
  padding-top: 6px;
  padding-bottom: 6px;
  cursor: pointer;
}
.submit-request-button {
  cursor: pointer;
}
.banner-bg {
  margin-top: -20px;
}
</style>