<template>
  <div class="section my-0 pb-0" id="elog" style="background-color: #faf9ff">
    <img
      v-if="$mq === 'desktop'"
      class="img-fluid left-side-prop"
      src="images/about/optimized/about-app-graphic.svg"
      alt="image"
    />

    <div class="container">
      <!-- Header -->
      <div class="row justify-content-center text-center">
        <div class="col-md-12">
          <h2 class="font-weight-bold mb-3">About the app</h2>
          <div class="row justify-content-center">
            <div class="col-lg-6 col-md-7 col-sm-10">
              <h5>
                Use your personal phone as access card to enter and leave from
                an establishment.
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div class="row align-items-center" style="margin-top: -90px">
        <div class="col-md-6 text-right p-0">
          <img
            src="images/about/optimized/elog-splash-screen.png"
            class="img-fluid"
          />
        </div>
        <div class="col-md-6">
          <h2 class="font-weight-bold">Key Features</h2>
          <div
            v-for="(keyFeature, index) in keyFeatures"
            :key="index"
            class="row pl-2"
          >
            <div class="col-md-12 d-flex align-items-center">
              <img :src="keyFeature.imageLink" class="img-fluid" width="40" />
              <div class="pt-3 pl-3">
                <h5>{{ keyFeature.text }}</h5>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 pt-3 pl-4">
              <img
                src="images/about/optimized/know-more-button.png"
                class="downloadable-link"
                width="150px"
                @click="onClickKnowMore"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "../../config/env-constants";

export default {
  name: "about",
  data() {
    return {
      keyFeatures: [
        {
          imageLink: "images/about/optimized/one.svg",
          text: "Touchless Attendance System",
        },
        {
          imageLink: "images/about/optimized/two.svg",
          text: "Offline Time and Location Logs",
        },
        {
          imageLink: "images/about/optimized/three.svg",
          text: "Contact Tracing",
        },
        {
          imageLink: "images/about/optimized/four.svg",
          text: "Asset Utilization Monitoring",
        },
        {
          imageLink: "images/about/optimized/five.svg",
          text: "Automated Reports",
        },
      ],
    };
  },
  methods: {
    onClickKnowMore() {
      window.open(config.aboutAppLink, "_blank");
    },
  },
};
</script>

<style scoped>
.left-side-prop {
  margin-top: 100px;
  width: 600px;
  position: absolute;
}
</style>