var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section app-brand-color",attrs:{"id":"qr-code"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 text-white",staticStyle:{"margin-top":"150px"}},[_c('h2',{staticClass:"font-weight-bold mb-2"},[_vm._v("Download your QR code here")]),_c('h5',[_vm._v("Register first to get your free qr code")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('router-link',{staticClass:"btn btn-round",staticStyle:{"background-color":"white"},attrs:{"to":"/register"}},[_vm._v(" Register ")])],1)]),_vm._m(0)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"card"},[_c('loading',{attrs:{"active":_vm.isLoading,"loader":"spinner","color":"#4599C0","is-full-page":false},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('div',{staticClass:"card-body"},[_vm._m(1),_c('ValidationObserver',{ref:"form"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"name"}},[_vm._v("First Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.firstName),expression:"form.firstName"}],staticClass:"form-control",attrs:{"type":"text","id":"name"},domProps:{"value":(_vm.form.firstName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "firstName", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"name"}},[_vm._v("Last Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.lastName),expression:"form.lastName"}],staticClass:"form-control",attrs:{"type":"text","id":"name"},domProps:{"value":(_vm.form.lastName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "lastName", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"contact-details"}},[_vm._v("Contact Details")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selContactDetails),expression:"selContactDetails"}],staticClass:"form-control",attrs:{"id":"contact-details"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selContactDetails=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"contactNo"}},[_vm._v("Phone Number")]),_c('option',{attrs:{"value":"email"}},[_vm._v("Email Address")])])]),(_vm.selContactDetails === 'email')?_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"Email Address","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"name"}},[_vm._v("Email Address")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"form-control",attrs:{"type":"text","id":"name"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,12032657)})],1):(_vm.selContactDetails === 'contactNo')?_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"name":"Phone Number","rules":"required|numeric|max:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"name"}},[_vm._v("Phone Number")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.contactNo),expression:"form.contactNo"}],staticClass:"form-control",attrs:{"type":"text","id":"name"},domProps:{"value":(_vm.form.contactNo)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "contactNo", $event.target.value)}}}),_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1):_vm._e(),_c('div',{staticClass:"form-group text-right"},[_c('button',{staticClass:"btn submit-button",attrs:{"round":""},on:{"click":_vm.onSubmit}},[_vm._v(" Request ")])])])],1)],1)])])]),_c('DownloadQRCode',{attrs:{"newlyCreated":false,"isOpen":_vm.isDownloadDialogOpen,"userDetails":_vm.userDetails},on:{"on-close":function($event){_vm.isDownloadDialogOpen = false}}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row pr-3 pb-5"},[_c('div',{staticClass:"text-center col-md-4 pt-5"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('img',{staticClass:"my-0",attrs:{"src":"images/qrcode/optimized/icon-register.svg","width":"80"}})])]),_c('div',{staticClass:"mt-3"},[_vm._v("Register")])]),_c('div',{staticClass:"text-center col-md-4 pt-5"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('img',{staticClass:"my-0",attrs:{"src":"images/qrcode/optimized/icon-download.svg","width":"80"}})])]),_c('div',{staticClass:"mt-3"},[_vm._v("Download")])]),_c('div',{staticClass:"text-center col-md-4 pt-5"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('img',{staticClass:"my-0",attrs:{"src":"images/qrcode/optimized/icon-scan.svg","width":"80"}})])]),_c('div',{staticClass:"mt-3"},[_vm._v("Scan")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row my-4 text-center"},[_c('div',{staticClass:"col-md-12 px-5 mb-4"},[_c('img',{staticClass:"my-0",attrs:{"src":"images/qrcode/optimized/icon-qr-code.svg","width":"100"}}),_c('h4',[_vm._v("Request your QR Code")]),_c('small',[_vm._v("For registered user only. We just need some information from you before generating your QR code.")])])])}]

export { render, staticRenderFns }