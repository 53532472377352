<template>
  <div class="section my-0" id="user-reviews" style="background-color: #faf9ff">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <h3 class="font-weight-bold">Our User Reviews</h3>
        </div>
        <div class="col-md-6 text-right">
          <img
            src="images/user-review/optimized/view-more-button.png"
            class="downloadable-link"
            width="150px"
            @click="onClickViewMore"
          />
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="user-review shadow rounded">
            <div class="row">
              <div class="col-md-2 text-center py-3">
                <img
                  class="img img-fluid"
                  src="images/user-review/optimized/user-avatar.webp"
                  width="80"
                  alt="image"
                />
              </div>
              <div class="col-md-6">
                <!-- Rating -->
                <div class="rating-block py-2">
                  <img
                    class="img-fluid mr-2"
                    src="images/user-review/optimized/review-star.svg"
                    width="30"
                    alt="image"
                  />
                  <img
                    class="img-fluid mr-2"
                    src="images/user-review/optimized/review-star.svg"
                    width="30"
                    alt="image"
                  />
                  <img
                    class="img-fluid mr-2"
                    src="images/user-review/optimized/review-star.svg"
                    width="30"
                    alt="image"
                  />
                  <img
                    class="img-fluid mr-2"
                    src="images/user-review/optimized/review-star.svg"
                    width="30"
                    alt="image"
                  />
                  <img
                    class="img-fluid"
                    src="images/user-review/optimized/review-star.svg"
                    width="30"
                    alt="image"
                  />
                </div>
                <!-- Review content -->
                <h5>
                  Keeps getting better through update. Very useful during this
                  pandemic.
                </h5>

                <h6>LeeJim Ramos</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "../../config/env-constants";

export default {
  name: "user-review",
  methods: {
    onClickViewMore() {
      window.open(config.allUserReviewsLink, "_blank");
    },
  },
};
</script>

<style scoped>
.user-review {
  background-color: #efeffe;
  width: 100%;
  min-height: 100px;
  padding: 10px 15px;
}
.img {
  border-radius: 50%;
}
body {
  padding-top: 70px;
}
.btn-grey {
  background-color: #d8d8d8;
  color: #fff;
}
.rating-block {
}
.bold {
  font-weight: 700;
}
.padding-bottom-7 {
  padding-bottom: 7px;
}
.review-block {
  background-color: #fafafa;
  border: 1px solid #efefef;
  padding: 15px;
  border-radius: 3px;
  margin-bottom: 15px;
}
.review-block-name {
  font-size: 12px;
  margin: 10px 0;
}
.review-block-date {
  font-size: 12px;
}
.review-block-rate {
  font-size: 13px;
  margin-bottom: 15px;
}
.review-block-title {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 10px;
}
.review-block-description {
  font-size: 13px;
}
</style>