<template>
  <div class="section app-brand-color" id="qr-code">
    <div class="container">
      <div class="row">
        <div class="col-md-6 text-white" style="margin-top: 150px">
          <h2 class="font-weight-bold mb-2">Download your QR code here</h2>
          <h5>Register first to get your free qr code</h5>
          <div class="row">
            <div class="col-md-12">
              <router-link
                class="btn btn-round"
                to="/register"
                style="background-color: white"
              >
                Register
              </router-link>
            </div>
          </div>
          <div class="row pr-3 pb-5">
            <div class="text-center col-md-4 pt-5">
              <div class="row">
                <div class="col-md-12">
                  <img
                    src="images/qrcode/optimized/icon-register.svg"
                    class="my-0"
                    width="80"
                  />
                </div>
              </div>
              <div class="mt-3">Register</div>
            </div>
            <div class="text-center col-md-4 pt-5">
              <div class="row">
                <div class="col-md-12">
                  <img
                    src="images/qrcode/optimized/icon-download.svg"
                    class="my-0"
                    width="80"
                  />
                </div>
              </div>
              <div class="mt-3">Download</div>
            </div>
            <div class="text-center col-md-4 pt-5">
              <div class="row">
                <div class="col-md-12">
                  <img
                    src="images/qrcode/optimized/icon-scan.svg"
                    class="my-0"
                    width="80"
                  />
                </div>
              </div>
              <div class="mt-3">Scan</div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <!-- Form -->
          <div class="card">
            <loading
              :active.sync="isLoading"
              loader="spinner"
              color="#4599C0"
              :is-full-page="false"
            />
            <div class="card-body">
              <div class="row my-4 text-center">
                <div class="col-md-12 px-5 mb-4">
                  <img
                    src="images/qrcode/optimized/icon-qr-code.svg"
                    class="my-0"
                    width="100"
                  />
                  <h4>Request your QR Code</h4>
                  <small
                    >For registered user only. We just need some information
                    from you before generating your QR code.</small
                  >
                </div>
              </div>

              <ValidationObserver ref="form">
                <div class="form-group">
                  <ValidationProvider
                    name="First Name"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <label for="name">First Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="name"
                      v-model="form.firstName"
                    />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <div class="form-group">
                  <ValidationProvider
                    name="Last Name"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <label for="name">Last Name</label>
                    <input
                      type="text"
                      class="form-control"
                      id="name"
                      v-model="form.lastName"
                    />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <div class="form-group">
                  <label for="contact-details">Contact Details</label>
                  <select
                    class="form-control"
                    id="contact-details"
                    v-model="selContactDetails"
                  >
                    <option value="contactNo">Phone Number</option>
                    <option value="email">Email Address</option>
                  </select>
                </div>

                <div class="form-group" v-if="selContactDetails === 'email'">
                  <ValidationProvider
                    name="Email Address"
                    rules="required|email"
                    v-slot="{ errors }"
                  >
                    <label for="name">Email Address</label>
                    <input
                      type="text"
                      class="form-control"
                      id="name"
                      v-model="form.email"
                    />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <div
                  class="form-group"
                  v-else-if="selContactDetails === 'contactNo'"
                >
                  <ValidationProvider
                    name="Phone Number"
                    rules="required|numeric|max:11"
                    v-slot="{ errors }"
                  >
                    <label for="name">Phone Number</label>
                    <input
                      type="text"
                      class="form-control"
                      id="name"
                      v-model="form.contactNo"
                    />
                    <span class="text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <div class="form-group text-right">
                  <button @click="onSubmit" class="btn submit-button" round>
                    Request
                  </button>
                </div>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>
    </div>

    <DownloadQRCode
      :newlyCreated="false"
      :isOpen="isDownloadDialogOpen"
      :userDetails="userDetails"
      @on-close="isDownloadDialogOpen = false"
    />
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import Card from "../../components/Cards/Card";
import { Button } from "../../components";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import api from "../../api/user";
import DownloadQRCode from "./qrcode/DownloadQRCode";
import _ from "lodash";

extend("email", email);
extend("required", required);

export default {
  name: "qrcode",
  components: {
    Card,
    [Button.name]: Button,
    ValidationProvider,
    ValidationObserver,
    Loading,
    DownloadQRCode,
  },
  data() {
    return {
      isLoading: false,
      selContactDetails: "contactNo",
      form: {
        firstName: "",
        lastName: "",
        contactNo: "",
        email: "",
      },
      isDownloadDialogOpen: false,
      userDetails: {},
    };
  },
  watch: {
    selContactDetails() {
      this.form.contactNo = "";
      this.form.email = "";
    },
  },
  methods: {
    async onSubmit() {
      const isSuccess = await this.$refs.form.validate();
      if (!isSuccess) return;

      try {
        this.isLoading = true;
        const { data } = await api.validateUserDetails(this.form);

        if (!_.isEmpty(data)) {
          this.userDetails = data;
          this.isDownloadDialogOpen = true;
          this.$refs.form.reset();
          this.resetForm();
        } else {
          this.isDownloadDialogOpen = false;
          this.$store.commit("alert/SHOW_DIALOG", {
            message: "User not found. please check your details.",
            status: "error",
          });
        }

        // hide loading indicator
        this.isLoading = false;
      } catch (error) {
        // hide loading indicator
        this.isLoading = false;
        this.$store.commit("alert/SHOW_DIALOG", {
          message: error.message,
          status: "error",
        });
      }
    },
    resetForm() {
      this.form = {
        firstName: "",
        lastName: "",
        contactNo: "",
        email: "",
      };
      this.selContactDetails = "contactNo";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/bootstrap.scss";
.footer-nav-links {
  color: white !important;
  cursor: pointer;
}
.contact-us {
  padding: 20px 50px;
  border-radius: 8px;
}
.submit-button {
  background-color: #4599c0;
  color: white;
}
.submit-button:hover {
  background-color: #3682a7;
  color: white;
}
</style>
