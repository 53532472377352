<template>
  <div
    class="section my-0 py-0"
    id="hub-guard"
    style="background-color: #faf9ff"
  >
    <img
      v-if="$mq === 'desktop'"
      class="img-fluid left-side-prop"
      src="images/about/optimized/about-app-graphic.svg"
      alt="image"
    />
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6 text-right p-0">
          <img
            src="images/hubguard/optimized/hub-guard-splash-screen.png"
            class="img-fluid"
          />
        </div>
        <div class="col-md-6">
          <h2 class="font-weight-bold">What is Hub Guard?</h2>
          <h5>
            <span class="font-weight-bold">Hub Guard</span> is a mobile
            application that creates a QR code station in an area to designate a
            specific code for every visitor. The designated QR code becomes
            their unique ID everywhere they go.
          </h5>
          <div class="row">
            <div class="col-md-12">
              <img
                src="images/buttons/optimized/google-play-badge.svg"
                class="downloadable-link"
                width="180px"
                @click="downloadHubGuardPlaystore"
              />
              <span>
                <img
                  src="images/buttons/optimized/app-gallery-badge.png"
                  class="downloadable-link"
                  width="180px"
                  @click="downloadElogAppGallery"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "../../config/env-constants";

export default {
  name: "hub-guard",
  methods: {
    downloadHubGuardPlaystore() {
      window.open(config.hubGuardPlayStoreLink, "_blank");
    },
    downloadElogAppGallery() {
      window.open(config.hubGuardAppGalleryLink, "_blank");
    },
  },
};
</script>

<style scoped>
.left-side-prop {
  margin-top: 80px;
  width: 600px;
  position: absolute;
}
</style>
