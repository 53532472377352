var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loading',{attrs:{"active":_vm.isLoading,"loader":"spinner","color":"#4599C0","is-full-page":false},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('div',{staticClass:"banner-bg clear-filter"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row text-center"},[_c('div',{staticClass:"col-md-12"},[_vm._m(0),_c('div',{staticClass:"row justify-content-md-center"},[_c('div',{staticClass:"col-md-5"},[_c('fg-input',{staticClass:"no-border form-control-lg search",attrs:{"placeholder":"Type keywords to find answer","color":"white"},on:{"input":_vm.onSearch},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)]),_c('div',{staticClass:"row justify-content-md-center"},[_c('div',{staticClass:"col-md-4"},[(_vm.searchQuery !== '')?_c('span',[_vm._v(" "+_vm._s(_vm.isSearching === true ? "Searching for your question..." : "Did you find the answer?")+" ")]):_vm._e()])])])])])]),_c('div',{staticClass:"main"},[_c('div',{staticClass:"section my-0"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3"},[_c('nav',{staticClass:"nav flex-column"},_vm._l((_vm.categories),function(category,index){return _c('a',{key:index,class:("nav-link " + (category === _vm.currTab
                    ? 'footer-nav-links-active'
                    : 'footer-nav-links')),attrs:{"href":true},on:{"click":function($event){$event.preventDefault();return _vm.onTabClick(category)}}},[_vm._v(_vm._s(_vm._f("categoryFormatter")(category)))])}),0)]),_c('div',{staticClass:"col-md-9"},[(
                _vm.noResultsFound &&
                !_vm.isFormRequestShown &&
                !_vm.isSearching &&
                _vm.searchQuery
              )?[_c('h4',{staticClass:"mt-0"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.noResultsFound)}})]),_c('a',{staticClass:"submit-request-button",attrs:{"href":_vm.isFormRequestShown},on:{"click":function($event){$event.preventDefault();_vm.isFormRequestShown = true}}},[_vm._v("Submit a request")])]:_vm._e(),(_vm.isFormRequestShown && _vm.searchedItems.length === 0)?_c('FormRequest',{on:{"onSuccess":_vm.onRequestSuccess,"onCancel":function($event){_vm.isFormRequestShown = false}}}):_vm._e(),(!_vm.searchQuery)?_c('h4',{staticClass:"text-primary mt-0"},[_vm._v(" "+_vm._s(_vm._f("categoryFormatter")(_vm.currTab))+" ")]):_vm._e(),_c('collapse',[_vm._l((_vm.searchedItems),function(item,index){return [_c('collapse-item',{key:index,staticClass:"mb-1",attrs:{"title":item.question,"titleBadge":_vm.searchQuery ? item.category : '',"name":index + 1,"noIcon":true}},[_c('span',{domProps:{"innerHTML":_vm._s(item.answer)}})]),_c('hr',{key:index + 'a'})]})],2)],2)])])])]),_c('alert-dialog')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h2',[_c('strong',[_vm._v("How can we help you?")])])}]

export { render, staticRenderFns }