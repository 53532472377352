<template>
  <div class="page-header clear-filter" id="hero">
    <div class="page-header-image img-fluid banner-bg" />
    <div class="container text-left">
      <div class="row pt-2">
        <div class="col-md-6">
          <div
            class="row"
            :style="`margin-top: ${$mq === 'desktop' ? '110px;' : '0px'}`"
          >
            <div class="col-md-12">
              <h1 class="mb-3">Digital Logging Application</h1>
              <h3 class="mb-2">Everything is digital, accurate, and safe.</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-sm-12 pl-0">
              <img
                src="images/buttons/optimized/google-play-badge.svg"
                class="downloadable-link"
                width="180px"
                @click="downloadElogPlaystore"
              />
              <img
                src="images/buttons/optimized/app-gallery-badge.png"
                class="downloadable-link"
                width="180px"
                @click="downloadElogAppGallery"
              />
            </div>
          </div>
        </div>
        <div v-if="$mq === 'desktop'" class="col-md-6">
          <div class="row pt-5">
            <div class="col-md-12">
              <img src="images/phones/optimized/phones.svg" class="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "../../config/env-constants";

export default {
  name: "hero",
  methods: {
    downloadElogPlaystore() {
      window.open(config.elogPlayStoreLink, "_blank");
    },
    downloadElogFirebase() {
      window.open(config.elogFBStorageLink, "_blank");
    },
    downloadElogAppGallery() {
      window.open(config.eLogAppGalleryLink, "_blank");
    },
  },
};
</script>
