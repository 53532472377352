<template>
  <footer
    class="footer"
    :class="{ [`footer-${type}`]: type }"
    :data-background-color="backgroundColor"
  >
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-sm-12 py-4">
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <img
                src="images/optimized/elog-logo-white.svg"
                class="my-0"
                width="150"
              />
            </div>
            <div class="col-md-12 col-sm-12">
              <nav class="nav mt-3 pl-3">
                <a class="footer-nav-links" @click="navFbLink">
                  <img
                    src="images/footer/optimized/icon-facebook.svg"
                    class="my-0"
                  />
                </a>
                <a class="footer-nav-links" @click="navInstaLink">
                  <img
                    src="images/footer/optimized/icon-instagram.svg"
                    class="my-0 ml-3"
                  />
                </a>
                <a class="footer-nav-links" @click="navELogLink">
                  <img
                    src="images/footer/optimized/icon-linkedin.svg"
                    class="my-0 ml-3"
                  />
                </a>
              </nav>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-12 py-4">
          <h4 class="mt-0">LINKS</h4>
          <nav class="nav flex-column">
            <router-link class="nav-link footer-nav-links" to="/"
              >Home</router-link
            >
            <a class="nav-link footer-nav-links" href="/#features">Features</a>
            <a class="nav-link footer-nav-links" href="/#elog">eLog</a>
            <a class="nav-link footer-nav-links" href="/#hub-guard"
              >Hub Guard</a
            >
            <a class="nav-link footer-nav-links" href="/#qr-code">QR Code</a>
            <a class="nav-link footer-nav-links" href="/#user-reviews"
              >Our User</a
            >
          </nav>
        </div>
        <div class="col-md-3 col-sm-12 py-4">
          <h4 class="mt-0">Support</h4>
          <nav class="nav flex-column">
            <a class="nav-link footer-nav-links" href="/#contact-us">Contact</a>
            <a class="nav-link footer-nav-links" href="/register">Register</a>
          </nav>
        </div>
        <div class="col-md-3 col-sm-12 py-4">
          <h4 class="mt-0">CONTACT US</h4>
          <nav class="nav flex-column">
            <a class="nav-link footer-nav-links">0917 111 8008</a>
            <a class="nav-link footer-nav-links">{{ supportEmail }}</a>
            <a class="nav-link footer-nav-links"
              >Diezmo-Road Brgy. Pulo Cabuyao</a
            >
            <a class="nav-link footer-nav-links">Laguna, Philippines, 4025</a>
          </nav>
        </div>
      </div>
      <div class="row py-4">
        <div class="col-md-6 col-sm-12 text-left">
          &copy; eLog {{ year }}. All rights reserved
        </div>
        <div
          :class="`col-md-6 col-sm-12 ${
            $mq === 'desktop' ? 'text-right' : 'text-left mt-3'
          }`"
        >
          <a class="nav-link footer-nav-links" @click="navPrivacyPolicyLink"
            >Privacy Policy</a
          >
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { Button, FormGroupInput } from "@/components";
import config from "@/config/env-constants";

export default {
  name: "main-footer",
  props: {
    backgroundColor: String,
    type: String,
  },
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  data() {
    return {
      year: new Date().getFullYear(),
      fbLink: config.fbLink,
      instagramLink: config.instagramLink,
      elogLink: config.elogLink,
      privacyPolicyLink: config.privacyPolicyLink,
      supportEmail: config.supportEmail,
    };
  },
  methods: {
    navFbLink() {
      window.open(this.fbLink, "_blank");
    },
    navInstaLink() {
      window.open(this.instagramLink, "_blank");
    },
    navELogLink() {
      window.open(this.elogLink, "_blank");
    },
    navPrivacyPolicyLink() {
      window.open(this.privacyPolicyLink, "_blank");
    },
  },
};
</script>

<style scoped>
.footer-nav-links {
  color: white !important;
  cursor: pointer;
}
</style>
