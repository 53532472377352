<template>
  <modal
    :show="isOpen"
    :showClose="true"
    header-classes="justify-content-center"
    body-classes="justify-content-center text-center"
    @close="$emit('on-close')"
  >
    <template slot="header">
      <div class="row">
        <div class="col-md-12">
          <h3 class="font-weight-bold mb-0">Success!</h3>
        </div>
      </div>
    </template>

    <template>
      <div class="row px-5">
        <div class="col-md-12">
          Your account has been
          {{ newlyCreated ? "successfully created" : "validated" }}. You can now
          download your qr code.
        </div>

        <!-- Placeholder -->
        <div class="row" hidden>
          <div class="col-md-12">
            <div class="text-center">
              <canvas id="canvas" :height="frameHeight" :width="frameWidth" />
            </div>
          </div>
        </div>
      </div>
    </template>

    <template slot="footer">
      <div class="row text-center" style="width: 100%">
        <div class="col-md-12">
          <n-button class="btn btn-success" @click="downloadQRCode"
            >Download QR Code</n-button
          >
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import QRCode from "qrcode";
import { Button, Modal } from "@/components";
import _ from "lodash";
import QrCodeBG from "../../../../public/images/qrcode/qrcode_bg.png";
import { truncate } from "../../../utils/string";
import config from "../../../config/env-constants";

export default {
  name: "download-qrcode",
  components: {
    Modal,
    [Button.name]: Button,
  },
  props: {
    newlyCreated: Boolean,
    userDetails: Object,
    isOpen: Boolean,
  },
  data() {
    return {
      frameHeight: 400,
      frameWidth: 224,
      qrCodeheight: 200,
      qrCodeWidth: 200,

      foregroundColor: "#000000",
      backgroundColor: "#ffffff",
      labelColor: "#9BAEBC",

      dataUrl: "",
    };
  },
  computed: {
    userDisplayName() {
      if (_.isEmpty(this.userDetails)) {
        return "";
      }

      return `${this.userDetails.firstName} ${this.userDetails.lastName}`.trim();
    },
  },
  watch: {
    isOpen(val) {
      if (val) {
        setTimeout(() => {
          this.draw();
        }, 0);
      }
    },
  },
  methods: {
    async draw() {
      const canvas = document.getElementById("canvas");
      const context = canvas.getContext("2d");

      const qrcode = await QRCode.toDataURL(this.userDetails.id);

      const images = {
        img1: QrCodeBG,
        img2: qrcode,
      };

      let texts = [
        {
          label: {
            title: "Name",
            x: 35,
            y: 260,
            w: 210,
            h: 10,
            c: this.labelColor,
          },
          value: {
            title: this.userDisplayName,
            x: 115,
            y: 260,
            w: 80,
            h: 10,
            c: this.foregroundColor,
          },
        },
        {
          label: {
            title: "Type",
            x: 35,
            y: 280,
            w: 210,
            h: 10,
            c: this.labelColor,
          },
          value: {
            title: this.userDetails.type,
            x: 115,
            y: 280,
            w: 80,
            h: 10,
            c: this.foregroundColor,
          },
        },
        {
          label: {
            title: "Company",
            x: 35,
            y: 300,
            w: 210,
            h: 10,
            c: this.labelColor,
          },
          value: {
            title: this.userDetails.company,
            x: 115,
            y: 300,
            w: 80,
            h: 10,
            c: this.foregroundColor,
          },
        },
        {
          label: {
            title: "Contact No.",
            x: 35,
            y: 320,
            w: 210,
            h: 10,
            c: this.labelColor,
          },
          value: {
            title: this.userDetails.contactNo,
            x: 115,
            y: 320,
            w: 80,
            h: 10,
            c: this.foregroundColor,
          },
        },
      ];

      if (this.userDetails.company === "Unassigned") {
        // Replace deleted object
        const deletedObj = texts.splice(2, 1);
        texts[2].label.y = deletedObj[0].label.y;
        texts[2].value.y = deletedObj[0].value.y;
      }

      loadImages(images, (image) => {
        context.drawImage(image.img1, 0, 0, 224, 400);
        context.drawImage(
          image.img2,
          12,
          12,
          this.qrCodeWidth,
          this.qrCodeheight
        );

        let linesAdded = 0;
        let valueY = 0;
        let labelY = 0;

        texts.forEach((text, index) => {
          if (index === 0) {
            valueY = text.value.y;
            labelY = text.label.y;
          } else {
            valueY += 15;
            labelY += 15;
          }

          if (linesAdded > 0) {
            valueY += linesAdded * 11;
            labelY += linesAdded * 11;
            linesAdded = 0; // Reset lines to be added to 0
          }

          linesAdded = loadText(
            context,
            text.value.title,
            text.value.x,
            valueY,
            text.value.w,
            text.value.h,
            text.value.c
          );

          loadText(
            context,
            text.label.title,
            text.label.x,
            labelY,
            text.label.w,
            text.label.h,
            text.label.c
          );
        });

        context.restore();
        this.emitDataURL();
      });
    },
    emitDataURL() {
      const dataURL = document.getElementById("canvas").toDataURL("image/png");
      this.dataUrl = dataURL;
    },
    async downloadQRCode() {
      const url = this.dataUrl;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${_.toLower(this.userDisplayName)}.png`);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    },
    resetComponent() {
      const canvas = document.getElementById("canvas");
      canvas.remove();
      this.dataUrl = "";
      this.userDetails = {};
    },
  },
};

const loadText = (context, text, x, y, maxWidth, lineHeight, color) => {
  var words = text.split(" ");
  var line = "";
  var linesAdded = 0;
  var hasEllipsis = false;

  for (var n = 0; n < words.length; n++) {
    var testLine = line + words[n] + " ";
    var metrics = context.measureText(testLine);
    var testWidth = metrics.width;

    context.fillStyle = color;
    if (testWidth > maxWidth && n > 0) {
      linesAdded++;

      if (linesAdded >= 3) {
        hasEllipsis = true;
        linesAdded -= 1;
      } else {
        context.fillText(line, x, y);
        y += lineHeight;
        line = words[n] + " ";
      }
    } else {
      line = testLine;
    }
  }

  if (hasEllipsis) {
    line += "...";
    hasEllipsis = false;
  }

  line = truncate(line, config.maxQRCodeStrLength);

  context.fillText(line, x, y);

  return linesAdded;
};

function loadImages(sources, callback) {
  var images = {};
  var loadedImages = 0;
  var numImages = 0;
  for (var src in sources) {
    numImages++;
  }
  for (var src in sources) {
    images[src] = new Image();
    images[src].onload = function () {
      if (++loadedImages >= numImages) {
        callback(images);
      }
    };
    images[src].src = sources[src];
  }
}
</script>