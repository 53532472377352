<template>
  <div
    class="section py-0 my-0"
    id="contact-us"
    style="background-color: #faf9ff"
  >
    <div class="container pb-5">
      <div class="contact-us shadow round app-brand-color">
        <div class="row">
          <div class="col-md-6 mt-5 text-white">
            <div class="row">
              <div class="col-md-12 text-white">
                <h2 class="font-weight-bold mb-0">Contact Us!</h2>
                <h5>Let us know if you have any questions</h5>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-12">
                <h3 class="mb-0">
                  <i class="fa fa-map-marker-alt fa-lg mr-3"></i>&nbsp; Find us
                </h3>
                <div style="padding-left: 50px; width: 260px">
                  Diezmo-Road Brgy. Pulo Cabuyao, Laguna Philippines
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-12">
                <h3 class="mb-0">
                  <i class="fa fa-mobile-alt fa-lg mr-3"></i>&nbsp; Give us a
                  ring
                </h3>
                <div style="padding-left: 50px; width: 260px">
                  0917 111 8008
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-12">
                <h3 class="mb-0">
                  <i class="fa fa-envelope fa-lg mr-3"></i>&nbsp; Email us
                </h3>
                <div style="padding-left: 56px; width: 260px">
                  {{ supportEmail }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 mt-5">
            <div class="card">
              <loading
                :active.sync="isLoading"
                loader="spinner"
                color="#4599C0"
                :is-full-page="false"
              />
              <div class="card-body">
                <ValidationObserver ref="form">
                  <div class="form-group">
                    <ValidationProvider
                      name="Name"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="name">Your Name</label>
                      <input
                        type="text"
                        class="form-control"
                        id="name"
                        v-model="form.name"
                      />
                      <span class="text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <ValidationProvider
                      name="Email"
                      rules="required|email"
                      v-slot="{ errors }"
                    >
                      <label for="email">Email Address</label>
                      <input
                        type="email"
                        class="form-control"
                        id="email"
                        v-model="form.email"
                      />
                      <span class="form-text text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <ValidationProvider
                      name="Message"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <label for="message">Your Message</label>
                      <textarea
                        class="form-control"
                        id="message"
                        rows="3"
                        v-model="form.message"
                      ></textarea>
                      <span class="form-text text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group text-right">
                    <button @click="onSubmit" class="btn submit-button" round>
                      Send Message
                    </button>
                  </div>
                </ValidationObserver>
                <alert-dialog />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import Card from "../../components/Cards/Card";
import { Button } from "../../components";
import api from "../../api/contactUs";
import AlertDialog from "../components/AlertDialog";
import config from "@/config/env-constants";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

extend("email", email);
extend("required", required);

export default {
  name: "contact-us",
  components: {
    Card,
    [Button.name]: Button,
    ValidationProvider,
    ValidationObserver,
    AlertDialog,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      form: {
        name: "",
        email: "",
        message: "",
        createdBy: "",
        dateCreated: "",
      },
      fbLink: config.fbLink,
      instagramLink: config.instagramLink,
      elogLink: config.elogLink,
      supportEmail: config.supportEmail,
    };
  },
  methods: {
    async onSubmit() {
      const isSuccess = await this.$refs.form.validate();
      if (!isSuccess) {
        return;
      }

      try {
        this.form.createdBy = this.form.email;
        this.form.dateCreated = new Date().getTime();
        this.isLoading = true;

        const data = await api.contactUs(this.form);
        this.$store.commit("alert/SHOW_DIALOG", {
          message: "Your message has been sent!",
          status: "success",
        });

        // hide loading indicator
        this.isLoading = false;
        this.resetForm();
        this.$refs.form.reset();
      } catch (error) {
        // hide loading indicator
        this.isLoading = false;
        this.$store.commit("alert/SHOW_DIALOG", {
          message: error.message,
          status: "error",
        });
      }
    },
    resetForm() {
      this.form = {
        name: "",
        email: "",
        message: "",
        createdBy: "",
        dateCreated: "",
      };
    },
    navFbLink() {
      window.open(this.fbLink, "_blank");
    },
    navInstaLink() {
      window.open(this.instagramLink, "_blank");
    },
    navELogLink() {
      window.open(this.elogLink, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/bootstrap.scss";
.footer-nav-links {
  color: white !important;
  cursor: pointer;
}
.contact-us {
  padding: 20px 50px;
  border-radius: 8px;
}
.submit-button {
  background-color: #4599c0;
  color: white;
}
.submit-button:hover {
  background-color: #3682a7;
  color: white;
}
</style>