<template>
  <div class="row">
    <loading
      :active.sync="isLoading"
      loader="spinner"
      color="#4599C0"
      :is-full-page="false"
    />
    <div class="col-md-6">
      <h3>Submit a request</h3>
      <ValidationObserver ref="form">
        <ValidationProvider
          name="Email"
          rules="required|email"
          v-slot="{ errors }"
        >
          <div class="form-group">
            <label for="email">
              Email address
              <span class="text-danger">*</span>
            </label>
            <input
              v-model="form.createdBy"
              type="email"
              class="form-control"
              id="email"
              aria-describedby="emailHelp"
              placeholder="Enter email"
            />
            <small id="emailHelp" class="form-text text-muted"
              >We'll never share your email with anyone else.</small
            >
            <span class="form-text text-danger" style="font-size: 12px">{{
              errors[0]
            }}</span>
          </div>
        </ValidationProvider>
        <ValidationProvider
          name="Question"
          rules="required"
          v-slot="{ errors }"
        >
          <div class="form-group">
            <label for="question">
              Question
              <span class="text-danger">*</span>
            </label>
            <textarea
              v-model="form.question"
              class="form-control"
              id="question"
              rows="2"
              style="padding: 0px"
            ></textarea>
            <span class="form-text text-danger" style="font-size: 12px">{{
              errors[0]
            }}</span>
          </div>
        </ValidationProvider>
        <button class="btn btn-primary" @click="onSubmit">Submit</button>
        <button class="btn btn-secondary" @click="onCancel">Cancel</button>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import api from "../../../api/faqs";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

extend("email", { ...email, message: "{_field_} field is invalid" });
extend("required", { ...required, message: "{_field_} field is required" });

export default {
  name: "form-request",
  components: {
    ValidationProvider,
    ValidationObserver,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      form: {
        question: "",
        answer: "",
        category: "",
        createdBy: "",
        isDeleted: false,
        dateCreated: "",
        dateUpdated: "",
        dateDeleted: null,
      },
    };
  },
  methods: {
    async onSubmit() {
      const isSuccess = await this.$refs.form.validate();
      if (!isSuccess) {
        return;
      }

      try {
        this.form.category = "pending";
        this.form.dateCreated = Date.now();
        this.form.dateUpdated = Date.now();
        this.isLoading = true;

        const result = await api.createFaq(this.form);

        // hide loading indicator
        this.isLoading = false;
        this.$refs.form.reset();
        this.resetForm();
        this.$emit("onSuccess");
      } catch (error) {
        // hide loading indicator
        this.isLoading = false;
        this.$store.commit("alert/SHOW_DIALOG", {
          message: error.message,
          status: "error",
        });
      }
    },
    onCancel() {
      this.$emit("onCancel");
    },
    resetForm() {
      this.form = {
        question: "",
        answer: "",
        category: "",
        createdBy: "",
        isDeleted: false,
        dateCreated: "",
        dateUpdated: "",
        dateDeleted: null,
      };
    },
  },
};
</script>