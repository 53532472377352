<template>
  <div
    class="section"
    id="features"
    style="background-color: #faf9ff; margin-top: -150px"
  >
    <div class="container">
      <!-- Header -->
      <div class="row justify-content-center text-center pb-5">
        <div class="col-md-12">
          <h2 class="font-weight-bold mb-3">Amazing Features</h2>
          <div class="row justify-content-center">
            <div class="col-lg-7 col-md-7 col-sm-10">
              <h5>
                <span class="font-weight-bold">eLog</span> is an automated
                logging system that uses minimal resources for attendance
                logging. With the power of android phones and QR codes, having a
                touchless attendance system is easy peasy with eLog.
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-center">
        <div
          class="col-md-4 col-sm-12"
          v-for="(feature, index) in features"
          :key="index"
        >
          <div class="card">
            <img
              class="card-img-top feature-item-image"
              :src="feature.imageLink"
              alt="image"
            />
            <div class="card-body">
              <h4 class="card-title text-center font-weight-bold">
                {{ feature.title }}
              </h4>
              <p class="text-center font-weight-normal">
                {{ feature.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "features",
  data() {
    return {
      features: [
        {
          title: "Emergency Button",
          description:
            "Report community or company emergencies like accidents and calamities.",
          imageLink: "images/features/optimized/emergency-button-feature.svg",
        },
        {
          title: "Mobile Web Accessibility",
          description: "eLog can be accessed using different devices.",
          imageLink: "images/features/optimized/mobile-web-access-feature.svg",
        },
        {
          title: "Offline Mode",
          description:
            "Takes actions offline and then synchronize changes when internet connection is available.",
          imageLink: "images/features/optimized/offline-feature.svg",
        },
      ],
    };
  },
};
</script>

<style scoped>
.card {
  min-height: 380px;
  max-height: 380px;
  border-radius: 12px;
  background-color: #faf9ff;
}
.feature-item-image {
  padding: 20px 20px 0px 20px;
  height: 190px;
}
</style>